body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue {
  color: #26B4FF!important;
}


.swal2-container {
  z-index: 1000000!important;
}

.contractAppendum img {
  max-width: 100%!important;
}

.show-transition {
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.link {
  cursor: pointer;
}

#gradientCanvas {
  pointer-events: none!important;
}

.theme-bg-white {
  border-radius: 8px;
}

.card {
  border-radius:8px;
}

.ql-align-center {
  text-align:center;
}
